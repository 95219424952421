<template>
    <div>
        <van-skeleton :row="20" :loading="loading">
            <div class="" v-if="vipDataList && vipDataList.length > 0">
                <div class="vip-box" v-for="(item, index) in vipDataList" :key="index"
                    @click="toDetail(item.contract_address)">
                    <van-col span="4">
                        <img class="label-icon" :src="item.icon" alt="">
                    </van-col>
                    <van-col span="20" class="fc-jsb" style="height: 70px;">
                        <div class="fs-16">{{ item.name }}</div>
                        <div>{{ item.contract_address }}</div>
                        <div class="gray">{{ item.push_time }}</div>
                    </van-col>
                </div>
            </div>
            <div class="table-body" v-else>
                <van-empty :description="$t('noData')" />
            </div>
        </van-skeleton>
    </div>
</template>

<script>
import { getVipData } from '@/api/api'
import { mapState } from 'vuex'
import { Toast } from "vant";
export default {
    props: {
        // 用户等级 0是非会员
        level: {
            type: [Number, String],
            default: 0,
        }
    },
    data() {
        return {
            vipDataList: [],
            loading: true,
        }
    },
    watch: {
        chain(newVal, oldVal) {
            this.getDataList()
        },
        '$store.state.login.Token': function () {
            this.getDataList()
        }
    },
    computed: {
        ...mapState({
            chain: state => state.login.chain
        }),
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        getDataList() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethvip'
            } else {
                chain = 'solvip'
            }
            getVipData(chain, 'vip', 1, 100, '').then((res) => {
                this.vipDataList = res.data.data.list
                this.vipDataList.forEach(item => {
                    item.add_date = item.add_date.slice(0, 10)
                })
                this.loading = false
            })
        },
        // 跳转详情页
        toDetail(address) {
            if (this.level == 0) {
                Toast(this.$t('openVipTip'))
                return
            }
            this.$router.push({
                path: '/mobile/detail',
                query: {
                    address
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.table-head {
    width: 345px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 5px;
    margin: 21px auto 0;

    .table-head-title,
    .table-head-title1 {
        font-weight: bold;
        font-size: 16px;
        position: relative;
        text-align: center;
        z-index: 0;
    }

    .table-head-title::after,
    .table-head-title1::after {
        width: 36px;
        height: 5px;
        content: '';
        background: #70ED24;
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .table-head-title1::after {
        width: 70px;
    }

    .green-circle {
        width: 9px;
        height: 9px;
        background: #70ED24;
        border-radius: 50%;
        margin-left: 10px;
    }

}

.table-body {
    width: 345px;
    background: #FFFFFF;
    margin: 0 auto;

    .table-row {
        height: 50px;
    }

    .icon-img {
        width: 26px;
        height: 26px;
        margin-right: 7px;
    }

    .arrow-img {
        width: 10px;
        height: 10px;
        margin-right: 3px;
    }

    .status-img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }

    .bgc {
        background: #F7F8FF;
    }
}

.vip-box {
    width: 345px;
    height: 90px;
    background: #FFFCFC;
    background-size: cover;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.02);
    border-radius: 7px;
    margin: 0 auto 15px;
    padding: 16px 10px;
    font-size: 12px;

    .vip-title {
        font-weight: bold;
        font-size: 16px;
        margin-top: 6px;
    }

    .vip-chart {
        width: 32px;
        height: 18px;
        margin-left: 6px;
        margin-top: 6px;
    }
}
</style>