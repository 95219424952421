export default {
  lang: "English",
  search: "Search",
  searchPlaceholder: "Search",
  wallet: "Wallet",
  walletLogin: "Wallet Login",
  login: "Login",
  logined: "Logged in",
  logOut: "Exit",
  home: "Home",
  consulting: "Consulting",
  memberCenter: "Member",
  memberCenter2: "Member Center",
  newLive: "New Listing",
  potentialCurrency: "Potential",
  popularCoins: "Popular",
  tradingHotspots: "Most Buy",
  VIPSelection: "AI Selection",
  profitStatistics: "Profit statistics",
  more: "More",
  checkMore: "READ MORE",
  marketCap: "MCap",
  mobility: "Liq",
  txns: "Txns",
  holders: "Holders",
  volume: "VOLUME",
  buys: "BUYS",
  sells: "SELLS",
  txns24: "Txns(24h)",
  volume24: "Volume(24h)",
  dataInformation: "Data Information",
  collectionSuccessful: "Collection successful",
  shareSuccessful: "The sharing link has been copied to the clipboard",
  contractCode: "token",
  membershipDescription: "Membership Description",
  membershipExpiration: "Expiration",
  upgradeMembership: "Upgrade Membership",
  accountBalance: "Account balance",
  withdrawable: "withdrawable",
  withdrawal: "Withdrawal",
  accumulatedIncome: "Accumulated income",
  pendingReview: "Pending review",
  myTeam: "My team",
  inviteFriends: "Invite friends",
  inviteToEarnProfits: "Invite to earn profits",
  myCollection: "My collection",
  helpCenter: "Help center",
  openMembership: "Open Member",
  openTip: "Open platform membership and enjoy good content as a member",
  membershipBenefits: "Enjoy high-quality columns and exclusive information for members",
  highQualityColumn: "High quality column",
  newCoinRecommendation: "New coin recommendation",
  exclusiveCustomerService: "Customer service",
  greatValueDiscounts: "Great value discounts",
  memberBenefits: "Membership Benefits",
  authorizationContract: "Authorization",
  pay: "Pay",
  authorizationSuccessful: "Authorization successful",
  authorizationFailed: "Authorization failed",
  installMetaMask: "Please install the MetaMask wallet plugin",
  transactionSent: "Transaction sent",
  transactionConfirmed: "Transaction confirmed",
  transactionFailed: "Transaction failed",
  wantWithdrawal: "Withdrawal",
  withdrawalInstructions: "Withdrawal instructions",
  withdrawTip: "Please enter the amount you want to withdraw",
  withdrawalAddressTip: "Please enter the withdrawal address",
  minimum: "Minimum",
  remaining: "Remaining",
  WithdrawToCurrentAccount: "Withdraw to current account",
  serviceCharge: "Service Charge",
  notice: "Notice",
  inputWwithdrawalAmount: "Please enter the withdrawal amount",
  withdrawalCannotLess: "The withdrawal amount cannot be less than",
  withdrawalSuccessful: "Withdrawal successful",
  accumulatedMembershipDevelopment: "Accumulated membership development",
  accumulatedIncome: "Accumulated income",
  user: "User",
  profit: "Profit",
  noTeam: "No team",
  name: "Name",
  price: "Price",
  cancel: "Cancel",
  noCollection: "No collection",
  time: "Time",
  riskTip: "Risk in the cryptocurrency industry should be approached with caution",
  noData: "No Data",
  copySuccess: "Cope Success",
  WalletPlugInNotInstalled: "Wallet plug-in not installed",
  openVipTip: "Please activate VIP first",
  loginWallet: "Please log in to the wallet first",
  goToLoginTip: "You have not logged in yet, whether to go to the login page?",
  confirmExit: "Are you sure to log out?",
  loading: "Loading...",
  noMore: "No more",
  views: "Views",
  backList: "Back",
  pushTime: "Push time",
  insufficientBalance: "Insufficient Balance",
  failedObtainBalance: "Failed to obtain balance",
  register: "Register",
  email: "Email",
  password: "Password",
  confirmPass: "Confirm",
  noAccount: "Don't have an account?",
  registerNewAccount: "Register a new account",
  hasAccount: "Do you already have an account?",
  goToLogin: "Go to login",
  inputEmail: "Please enter your email address",
  inputValidEmail: "Please enter a valid email address",
  inputPassword: "Please input a password",
  passwordLengthLimit: "Password length at least 6 characters",
  inputPasswordAgain: "Please enter your password again",
  passwordAgainError: "Password entered is inconsistent!",

  confirm: "Confirm",
  tip: "Tip",
  changeChainTip: "You are currently connected to a non Ethereum mainnet. Do you want to switch to the Ethereum mainnet?",
  switchingSuccessful: "Switching Successful",
  noChainChange: "The target network has not been added yet. Please add the Ethereum mainnet",
  recharge: "Recharge",
  rechargeTip: "Please enter the amount you want to recharge",
  rechargeToCurrentAccount: "Recharge to current account",
  myOrder: "My Order",
  paymentCode: "Payment QR code",
  pushPrice: "Push price",
  profit: "Profit",
  DailyHighestPrice: "Daily highest price",
  emailPush: "Email push",
  bind: "Bind",
  bindEmail: "Bind Email",
  switchOn: "Successfully enabled push settings",
  switchOff: "Successfully closed push settings",
  bindSuccess: "Binding successful",
  bindFail: "Binding failed",
  openPushNotifications: "Push notifications",
  withdrawalBtn: "Withdrawal",
  selectCurrency: "Select Currency",
  searchCurrency: "Enter to search for currency",
  pleaseSelectCurrency: "Please select currency",
  paymentAmount: "Payment amount",
  paymentAddress: "Payment address",
  alreadyBound: "Already bound",
  confirmWithdrawalAddress: "Please confirm if the withdrawal has been made to the address:",
  heatIndex: "Heat index",
  potentialRating: "Potential rating",
  estimatedSuccessRate: "Success rate",
  aiScore: "AI score",
  socialIndex: "Social index",
  spreadIndex: "Spread index",
  FOMOIndex: "FOMO index",
  flightRisk: "Flight risk",
  averageHoldingPerCapita: "AHC",
  topHolders: "Top holders",
  positionStatus: "Position status",
  chipAnalysis: "Chip analysis",
  FDV: "FDV",
  normal: "normal",
  abnormal: "abnormal",
};
