<template>
    <div style="width: 100%;">
        <el-row class="top-box fr-ac">
            <el-col :span="4" class="logo-height fr-jc">
                <img class="logo" @click="toHome" src="@/assets/img/logo.png" alt="">
            </el-col>
            <el-col :span="11" class="logo-height fr-ac-jc">
                <div class="tab-box fr-ac-jc" :class="{ 'active': 0 == activeIndex }" @click="onTab(0, '')">{{
                $t('home')
            }}
                </div>
                <div class="tab-box fr-ac-jc" :class="{ 'active': 1 == activeIndex }" @click="onTab(1, 'pcNewLive', 1)">
                    {{
                $t('newLive')
            }}
                </div>
                <div class="tab-box fr-ac-jc" :class="{ 'active': 2 == activeIndex }" @click="onTab(2, 'pcNewLive', 2)">
                    {{
                $t('potentialCurrency') }}
                </div>
                <div class="tab-box fr-ac-jc" :class="{ 'active': 3 == activeIndex }" @click="onTab(3, 'pcNewLive', 3)">
                    {{
                $t('popularCoins') }}
                </div>
                <div class="tab-box fr-ac-jc" :class="{ 'active': 4 == activeIndex }" @click="onTab(4, 'pcNewLive', 4)">
                    {{
                $t('tradingHotspots') }}
                </div>
                <div class="tab-box fr-ac-jc" :class="{ 'active': 5 == activeIndex }" @click="onTab(5, 'vipSelection')">
                    {{
                $t('VIPSelection') }}
                </div>
                <!-- <div class="tab-box fr-ac-jc" :class="{ 'active': 6 == activeIndex }"
                    @click="onTab(6, 'pcNoticeIndex')">
                    {{ $t('consulting') }}
                </div> -->
                <div class="tab-box fr-ac-jc" :class="{ 'active': 7 == activeIndex }"
                    @click="onTab(7, 'pcMemberIndex')">
                    {{ $t('memberCenter') }}
                </div>
            </el-col>
            <el-col :span="9" class="logo-height fr-ac-jc">
                <div class="search-box fr-ac" @click="onSearch()">
                    <van-icon name="search" color="#5F626B" size="20" />
                    <p>{{ $t('searchPlaceholder') }}</p>
                </div>
                <div class="chain-box fr-ac-jc" @click="onChain()">
                    <p>{{ $store.state.login.chain }}</p>
                    <van-icon name="arrow-down" size="15px" color="#666666" />
                </div>
                <!-- 切换语言 -->
                <div class="language-wrap" @mouseover="mouseOverLanguage" @mouseleave="mouseLeaveLanguage">
                    <div class="language-box fr-ac">
                        <img v-if="languageIndex === 0" src="@/assets/img/english-pc.png" alt="">
                        <img v-else src="@/assets/img/chinese-pc.png" alt="">
                        <p>{{ $t('lang') }}</p>
                        <div class="language-pop2 fc-ac-jc" v-if="isShowLanguagePop" @mouseover="mouseOverLanguage"
                            @mouseleave="mouseLeaveLanguage">
                            <div class="fr-ac select-language" :class="{ 'active': languageIndex === 0 }"
                                @click="onSelectLanguage(0)">
                                <img src="@/assets/img/english-pc.png" alt="">
                                <div class="fw-b fs-14">English</div>
                            </div>
                            <div class="fr-ac select-language" :class="{ 'active': languageIndex === 1 }"
                                @click="onSelectLanguage(1)">
                                <img src="@/assets/img/chinese-pc.png" alt="">
                                <div class="fw-b fs-14">中文</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 钱包 -->
                <div class="chain-box fr-ac-jc" @click="showWalletPop()">
                    <img class="wallet-icon" src="@/assets/img/wallet-green.png" alt="">
                    <div v-if="$store.state.login.Token">{{ $t("logOut") }}</div>
                    <div v-else>{{ $t("login") }}</div>
                </div>
            </el-col>
        </el-row>
        <div class="seat"></div>

        <!-- 钱包连接弹窗 -->
        <van-popup v-model="isShowConnectWallet" closeable round @close="closeConnectWallet()" z-index="100">
            <div class="wrapper fc-ac">
                <div class="mt-30 fw-b fs-16">Connect Wallet</div>
                <div class="fs-10 gray ta-c" style="margin-top: 7px;margin-bottom: 24px;">
                    <p>Securely connect your wallet to start</p>
                    <p>your Web3 journey</p>
                </div>
                <!-- <van-row class="wallet-box fr-ac">
          <van-col span="6">
            <img src="@/assets/img/wallet1.png" alt="">
          </van-col>
          <van-col span="10">
            <div class="fs-14 fw-b">SOLANA</div>
            <div class="light-gray fs-10">walletextenslon</div>
          </van-col>
          <van-col span="6">
            <div class="connect-btn" @click="connectWallet('solana')">Connect</div>
          </van-col>
        </van-row> -->
                <van-row class="wallet-box fr-ac">
                    <van-col span="6">
                        <img src="@/assets/img/wallet2.png" alt="">
                    </van-col>
                    <van-col span="10">
                        <div class="fs-14 fw-b">ETHEREUM</div>
                        <div class="light-gray fs-10">walletextenslon</div>
                    </van-col>
                    <van-col span="6">
                        <div class="connect-btn" @click="connectWallet('ethereum')">Connect</div>
                    </van-col>
                </van-row>
            </div>
        </van-popup>

        <!-- 链选择弹窗 -->
        <van-popup v-model="isShowChainPop" closeable round @close="closeChainPop()" z-index="100">
            <div class="wrapper fc-ac">
                <div class="mt-30 fw-b fs-16 mb-20">Choose Chain</div>
                <van-row class="wallet-box fr-ac">
                    <van-col span="6">
                        <img src="@/assets/img/wallet1.png" alt="">
                    </van-col>
                    <van-col span="10">
                        <div class="fs-14 fw-b">SOLANA</div>
                    </van-col>
                    <van-col span="6">
                        <div class="connect-btn" @click="changeChain('SOL')">Change</div>
                    </van-col>
                </van-row>
                <van-row class="wallet-box fr-ac">
                    <van-col span="6">
                        <img src="@/assets/img/wallet2.png" alt="">
                    </van-col>
                    <van-col span="10">
                        <div class="fs-14 fw-b">ETHEREUM</div>
                    </van-col>
                    <van-col span="6">
                        <div class="connect-btn" @click="changeChain('ETH')">Change</div>
                    </van-col>
                </van-row>
            </div>
        </van-popup>

        <!-- 搜索弹窗 -->
        <!-- <van-popup v-model="isShowSearch" round @close="closeSearchPop()" z-index="100">
            <div class="search-wrap">
                <van-search v-model="searchKey" placeholder="请输入完整合约地址后搜索" shape="round" show-action
                    action-text="Search" />
                <div class="search-tip">Trending of token searches</div>
                <div class="search-list fr-ac-jsb fw">
                    <div class="fr-ac search-item" v-for="item in 9" :key="item">
                        <img src="@/assets/img/head.png" alt="">
                        <div class="fs-14 fw-b">DETOX<span class="light-gray">/SOL</span></div>
                    </div>
                </div>
            </div>
        </van-popup> -->

        <!-- 搜索弹窗 -->
        <van-popup v-model="isShowSearch" round @close="closeSearchPop()" z-index="100">
            <div class="search-wrap hide-scrollbar">
                <!-- <van-search v-model="searchKey" :placeholder="$t('searchPlaceholder')" shape="round" show-action
                    @input="handleInput" @clear="handleClear">
                    <template #action>
                        <div @click="closeSearchPop()">{{ $t('cancel') }}</div>
                    </template>
</van-search> -->
                <el-input :placeholder="$t('searchPlaceholder')" v-model="searchKey" clearable @input="handleInput"
                    @clear="handleClear">
                    <el-button slot="append" icon="el-icon-search" @click="clickSearch"></el-button>
                </el-input>
                <!-- <van-list v-model="loading" :finished="finished" :loading-text="$t('loading')"
                    :finished-text="$t('noMore')" @load="getSearchList"> -->
                <div class="search-list fr-ac fw" v-if="searchDetail && searchDetail.baseToken?.address">
                    <div class="fr-ac search-item" @click="toDetail(searchDetail.baseToken?.address)">
                        <img :src="searchDetail.info?.imageUrl || require('@/assets/img/head.png')" alt="">
                        <div class="fs-12 fr-ac">{{ searchDetail.baseToken?.symbol }}/<span class="light-gray">{{
                searchDetail.chainId }}</span></div>
                    </div>
                </div>
                <div class="search-list fc-ac" v-else>
                    <van-empty :description="$t('noData')" />
                </div>
                <!-- </van-list> -->
            </div>
        </van-popup>
    </div>
</template>

<script>
import util from '@/utils/util.js'
import store from "@/store";
import {
    loginAPI,
} from '@/api/login'
import i18n from '@/lang'
import web3JS from '@/utils/web3'
import des from '@/utils/des'
import { Toast } from "vant";
import { getData, getVipData } from '@/api/api'
import { getUserInfo } from '@/api/member'
import mixin from '@/utils/mixin'; //引入mixin文件
import { getDetail } from '@/api/api'
export default {
    mixins: [mixin],  // 将混入对象添加到 mixins 数组中
    data() {
        return {
            isShowLanguagePop: false, //是否显示语言弹窗
            languageIndex: 0, //语言索引
            isShowConnectWallet: false, //是否显示钱包连接弹窗
            isShowSearch: false, //是否显示搜索弹窗
            searchKey: '', //搜索关键词
            searchDataList: [], // 搜索结果
            page: 1,
            loading: false,
            finished: false,
            timeout: null,

            walletAddress: null, // 保存钱包地址
            web3: null, // 用来与以太坊交互的 Web3 实例
            isShowChainPop: false, //是否显示链弹窗
            chain: 'ETH', //链
            activeIndex: 0, //当前导航索引
            level: 0, //等级
            searchDetail: null, //搜索详情
        }
    },
    watch: {
        '$route'(to, from) {
            // 当路由变化时，触发此回调
            let path = to.path;  // 更新当前路由路径
            if (path == '/pc/') {
                this.activeIndex = 0
            } else if (path == '/pc/pcNewLive') {
                this.activeIndex = to.query.type
            } else if (path == '/pc/vipSelection') {
                this.activeIndex = 5
            } else if (path.includes('/pc/pcNoticeIndex')) {
                this.activeIndex = 6
            } else if (path.includes('/pc/pcMemberIndex')) {
                this.activeIndex = 7
            }
        }
    },
    mounted() {
        this.activeIndex = localStorage.getItem('tabIndex') || 0
        if (web3JS) {
            let walletAddress = web3JS.checkMetaMaskLoginStatus()
            if (!walletAddress) {
                this.walletAddress = null
                store.commit("login/clearAccount1");
                // store.commit("login/clearToken");
            }
        }
        if (this.$store.state.login.gameLang == 'CN') {
            this.languageIndex = 1
        } else {
            this.languageIndex = 0
        }
        this.chain = this.$store.state.login.chain
    },
    methods: {
        toHome() {
            this.activeIndex = 0
            localStorage.setItem('tabIndex', 0)
            this.$router.push('/pc')
        },
        onTab(index, path, type) {
            this.activeIndex = index
            localStorage.setItem('tabIndex', index)
            if (path === 'pcNewLive') {
                this.$router.push('/pc/' + path + '?type=' + type)
            } else {
                this.$router.push('/pc/' + path)
            }

        },
        //鼠标移入语言弹窗
        mouseOverLanguage() {
            this.isShowLanguagePop = true
        },
        //鼠标离开语言弹窗
        mouseLeaveLanguage() {
            this.isShowLanguagePop = false
        },
        //选择语言
        onSelectLanguage(index) {
            this.languageIndex = index
            if (index === 1) {
                this.$i18n.locale = 'CN'
                this.$store.commit('login/changeGameLang', 'CN')
            }
            if (index === 0) {
                this.$i18n.locale = 'EN'
                this.$store.commit('login/changeGameLang', 'EN')
            }
        },
        //打开钱包连接弹窗
        showWalletPop() {
            if (!this.$store.state.login.Token) {
                // this.isShowConnectWallet = true
                this.$router.push({ path: '/pc/login' })
            } else {
                this.$confirm(this.$t('confirmExit'), {
                    confirmButtonText: this.$t('confirm'),
                    cancelButtonText: this.$t('cancel'),
                }).then(() => {
                    this.$store.commit('login/clearToken')
                    // this.$store.commit('login/clearAccount1')
                }).catch(() => {

                });
            }
        },
        //关闭钱包连接弹窗
        closeConnectWallet() {
            this.isShowConnectWallet = false
        },
        //关闭链弹窗
        closeChainPop() {
            this.isShowChainPop = false
        },
        //关闭搜索弹窗
        closeSearchPop() {
            this.isShowSearch = false
        },
        //打开搜索弹窗
        onSearch() {
            this.isShowSearch = true
            this.getUserInfo()
        },
        //搜索
        // toSearch() {
        //     let chain = ''
        //     if (this.$store.state.login.chain == 'ETH') {
        //         chain = 'ethvip'
        //     } else {
        //         chain = 'solvip'
        //     }
        //     getVipData(chain, 1, 10, this.searchKey).then((res) => {
        //         this.searchDataList = res.data.data.list
        //     })
        // },
        //搜索
        handleInput(query) {
            // 清除上一次的定时器
            clearTimeout(this.timeout);

            // 设置一个新的定时器来延迟调用搜索方法
            this.timeout = setTimeout(() => {
                this.searchKey = query;
                this.page = 1;  // 重置为第一页
                this.searchDataList = [];
                this.finished = false;
                this.getSearchList();  // 执行数据加载
            }, 500); // 500ms 延迟
        },
        handleClear() {
            this.searchKey = '';
            this.searchDetail = null
            this.getSearchList();  // 执行数据加载
        },
        clickSearch() {
            this.page = 1;  // 重置为第一页
            this.searchDataList = [];
            this.finished = false;
            this.getSearchList();  // 执行数据加载
        },
        //获取搜索列表
        getSearchList() {
            // let chain = ''
            // if (this.chain == 'ETH') {
            //     chain = 'ethvip'
            // } else {
            //     chain = 'solvip'
            // }
            // getVipData(chain, 'search', this.page, 10, this.searchKey).then((res) => {
            //     this.searchDataList = this.page === 1 ? res.data.data.list : [...this.searchDataList, ...res.data.data.list];

            //     this.loading = false;
            //     this.page++;
            //     if (this.searchDataList.length >= res.data.data.total) {
            //         this.finished = true;
            //     }
            // })
            getDetail(this.searchKey).then((res) => {
                this.searchDetail = res.data.pairs[0]
            });
        },
        //打开链选择弹窗
        onChain() {
            this.isShowChainPop = true
        },
        // 连接钱包
        async connectWallet() {
            // web3JS.login().then((res) => {
            //     this.walletAddress = res
            // })
            this.$router.push({ path: '/pc/login' })
            this.isShowConnectWallet = false
        },
        //选择链
        changeChain(type) {
            this.chain = type;
            this.isShowChainPop = false
            store.commit("login/changeChain", type);
            if (this.$route.path == '/pc/detail') {
                this.$router.go(-1)
            }

        },
        handleAccountsChanged(accounts) {
            this.walletAddress = accounts[0];
            console.log('钱包切换');
            if (accounts.length > 0) {
                store.commit("login/changeAccount1", this.walletAddress);
            } else {
                store.commit("login/changeAccount1", null);
            }
            window.location.reload();
        },
        // 跳转详情页
        toDetail(address) {
            this.$router.push({
                path: '/pc/detail',
                query: {
                    address,
                }
            })
            this.isShowSearch = false
        },
        // 获取用户信息
        getUserInfo() {
            getUserInfo().then((res) => {
                if (res.data.code !== 0) {
                    this.$store.commit("login/clearToken");
                    return
                }
                this.level = res.data.data.userinfo.level
            });
        },
    }
}
</script>

<style lang="less" scoped>
.logo {
    width: 221px;
    height: 62px;
    cursor: pointer;
}

.seat {
    height: 100px;
    width: 100%;
}

.top-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 99;

    .logo-height {
        height: 62px;
    }

    .tab-box {
        width: 110px;
        height: 62px;
        color: #5F626B;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        text-align: center;

        &.active {
            color: #000000;
            position: relative;
        }

        &.active::after {
            content: '';
            display: block;
            width: 38px;
            height: 4px;
            background: #70ED24;
            border-radius: 2px;
            position: absolute;
            bottom: 12px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .search-box {
        background: #F7F7F7;
        border-radius: 39px;
        padding: 10px 20px 10px 10px;
        width: 180px;
        cursor: pointer;

        p {
            font-size: 14px;
            color: #999BAA;
            margin-left: 8px;
        }
    }

    .chain-box {
        width: 90px;
        height: 40px;
        background: #202021;
        color: #70ED24;
        border: 2px solid #70ED24;
        padding: 7px 10px 7px 15px;
        border-radius: 39px;
        line-height: 1;
        margin-left: 10px;
        cursor: pointer;

        p {
            margin-right: 6px;
        }

        .wallet-icon {
            width: 32px;
            height: 32px;
        }
    }

    .language-wrap {
        height: 62px;
        padding: 11px 0;
    }

    .language-box {
        width: 90px;
        height: 40px;
        border-radius: 24px;
        border: 2px solid #70ED24;
        position: relative;
        cursor: pointer;
        margin-left: 10px;

        img {
            width: 36px;
            height: 36px;
            margin-top: 1px;
            margin-right: 2px;
        }

        .language-pop2 {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 45px;
            width: 130px;
            height: 105px;
            background: #fff;
            border-radius: 5px;
            z-index: 999;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);

            .select-language {
                width: 118px;
                height: 42px;
                border-radius: 5px;
                padding-left: 6px;
                margin-top: 6px;
                border-radius: 5px;

                &.active {
                    background: #EEEEEE;
                }
            }

            img {
                width: 40px;
                height: 40px;
                margin-right: 9px;
            }
        }
    }
}

// 弹窗
/deep/ .van-overlay {
    background-color: rgba(0, 0, 0, .2);
}

.wrapper {
    width: 295px;
    // height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    padding-bottom: 10px;

    .wallet-box {
        width: 230px;
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin: 0 auto 13px;

        img {
            width: 41px;
            height: 41px;
            margin: 0 8px;
        }

        .connect-btn {
            width: 70px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            background: #000000;
            border-radius: 5px;
            font-weight: bold;
            font-size: 12px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}

.search-wrap {
    width: 600px;
    height: 480px;
    background: #FFFFFF;
    // box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 22px 19px 10px;

    .search-tip {
        font-weight: bold;
        font-size: 19px;
        margin-top: 68px;
        margin-bottom: 17px;
    }

    .search-list {
        padding-top: 20px;

        .search-item {
            width: 98%;
            height: 50px;
            line-height: 50px;
            background: #F2F3F7;
            border-radius: 5px;
            padding: 0 20px;
            margin-bottom: 13px;
            cursor: pointer;
            margin-right: 20px;
        }

        img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border-radius: 50%;
        }
    }
}
</style>