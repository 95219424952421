<template>
    <div>
        <div class="fr-ac-jsb width-100 fw" v-if="vipDataList && vipDataList.length > 0">
            <div v-for="(item, index) in vipDataList" :key="index" class="mb-20"
                @click="toDetail(item.virtual_id, item.view_count, 'vip')">
                <div class="new-currency-content-center">
                    <van-row gutter="14" class="info-top fr-ac">
                        <van-col span="5">
                            <img class="label-icon" :src="require('@/assets/img/head.png')" alt="">
                        </van-col>
                        <van-col span="19">
                            <div class="fr-ac mb-10">
                                <div class="fr-ac">
                                    <img class="top-vip-icon" src="@/assets/img/vip_icon/icon_14.png" alt="">
                                    <span class="fw-b">{{ $t('contractCode') }}</span>：
                                </div>
                                <span class="light-gray">{{ item.contract_address ?
            item.contract_address.substring(0, 10) : item.contract_address }}</span>
                            </div>
                            <div class="fr-ac mb-10">
                                <div class="fr-ac">
                                    <img class="top-vip-icon" src="@/assets/img/vip_icon/icon_15.png" alt="">
                                    <span class="fw-b">{{ $t('pushPrice') }}</span>：
                                </div>
                                <span class="light-gray">{{ item.push_price || '-' }}</span>
                            </div>
                            <div class="fr-ac">
                                <div class="fr-ac">
                                    <img class="top-vip-icon" src="@/assets/img/vip_icon/icon_16.png" alt="">
                                    <span class="fw-b">{{ $t('pushTime') }}</span>：
                                </div>
                                <span class="light-gray">{{ item.push_time || '-' }}</span>
                            </div>
                        </van-col>
                    </van-row>
                    <van-row gutter="35" class="pl-20 pr-20 mb-20">
                        <van-col span="12" class="mt-20">
                            <div class="fr-ac-jsb mt-10">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_1.png" alt="">
                                    <div class="fw-b">{{ $t('potentialRating') }}</div>
                                </div>
                                <div class="light-gray">{{ item.potential_score || '-' }}</div>
                            </div>
                            <div class="fr-ac-jsb mt-20">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_3.png" alt="">
                                    <div class="fw-b">{{ $t('aiScore') }}</div>
                                </div>
                                <div class="light-gray">{{ item.ai_score || '-' }}</div>
                            </div>
                            <div class="fr-ac-jsb mt-20">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_5.png" alt="">
                                    <div class="fw-b">{{ $t('socialIndex') }}</div>
                                </div>
                                <div class="light-gray">{{ item.social_index || '-' }}</div>
                            </div>
                            <div class="fr-ac-jsb mt-20">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_7.png" alt="">
                                    <div class="fw-b">{{ $t('FOMOIndex') }}</div>
                                </div>
                                <div class="light-gray">{{ item.fomo_index || '—' }}</div>
                            </div>
                            <div class="fr-ac-jsb mt-20">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_13.png" alt="">
                                    <div class="fw-b">{{ $t('topHolders') }}</div>
                                </div>
                                <div class="light-gray">{{ item.top10_holder == 'true' ? $t('normal') : $t('abnormal')
                                    }}</div>
                            </div>
                        </van-col>
                        <van-col span="12" class="mt-20">
                            <div class="fr-ac-jsb mt-10">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_2.png" alt="">
                                    <div class="fw-b">{{ $t('estimatedSuccessRate') }}</div>
                                </div>
                                <div class="light-gray">{{ item.estimated_success_rate || '-' }}%</div>
                            </div>
                            <div class="fr-ac-jsb mt-20">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_4.png" alt="">
                                    <div class="fw-b">{{ $t('heatIndex') }}</div>
                                </div>
                                <div class="light-gray">{{ item.heat_index || '-' }}</div>
                            </div>
                            <div class="fr-ac-jsb mt-20">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_6.png" alt="">
                                    <div class="fw-b">{{ $t('spreadIndex') }}</div>
                                </div>
                                <div class="light-gray">{{ item.spread_index || '-' }}</div>
                            </div>
                            <div class="fr-ac-jsb mt-20">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_8.png" alt="">
                                    <div class="fw-b">{{ $t('flightRisk') }}</div>
                                </div>
                                <div class="light-gray">{{ item.runaway_probability || '-' }}%</div>
                            </div>
                            <div class="fr-ac-jsb mt-20">
                                <div class="fr-ac">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_10.png" alt="">
                                    <div class="fw-b">{{ $t('averageHoldingPerCapita') }}</div>
                                </div>
                                <div class="light-gray">{{ item.average_holding_amount || '—' }}u</div>
                            </div>
                        </van-col>
                    </van-row>
                    <van-row gutter="35" class="pl-20 pr-20" style="border-top: 1px dotted #e5e5e5;">
                        <van-col class="width-100">
                            <div class="fr-ac mt-20">
                                <div class="fr-ac flex-s-0 mr-10">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_11.png" alt="">
                                    <div class="fw-b">{{ $t('positionStatus') }}</div>
                                </div>
                                <el-tooltip class="item" effect="dark"
                                    :content="language === 'CN' ? item.holding_conditioncn : item.holding_condition"
                                    placement="top">
                                    <div class="light-gray yc">{{ language === 'CN' ? item.holding_conditioncn || '—' :
            item.holding_condition || '—' }}</div>
                                </el-tooltip>
                            </div>
                            <div class="fr-ac mt-20">
                                <div class="fr-ac flex-s-0 mr-10">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_12.png" alt="">
                                    <div class="fw-b">{{ $t('chipAnalysis') }}</div>
                                </div>
                                <!-- <div class="light-gray">{{ item.holding_analysis || '—' }}</div> -->
                                <el-tooltip class="item" effect="dark"
                                    :content="language === 'CN' ? item.holding_analysis : item.chip_analysis"
                                    placement="bottom">
                                    <div class="light-gray yc">{{ language === 'CN' ? item.holding_analysis || '—' :
            item.chip_analysis || '—'}}</div>
                                </el-tooltip>
                            </div>
                            <div class="fr-ac mt-20">
                                <div class="fr-ac mr-10">
                                    <img class="vip-icon" src="@/assets/img/vip_icon/icon_9.png" alt="">
                                    <div class="fw-b">{{ $t('FDV') }}</div>
                                </div>
                                <div class="light-gray">{{ item.fdv || '—' }}</div>
                            </div>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
        <div v-else>
            <van-empty :description="$t('noData')" />
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
import { mapState } from 'vuex';
export default {
    props: {
        vipDataList: {
            type: Array,
            default: () => []
        },
        level: {
            type: [String, Number],
            default: 0
        }
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            language: state => state.login.gameLang
        }),
    },
    watch: {

    },
    mounted() {

    },
    methods: {
        // 跳转详情页
        toDetail(address, view_count, type) {
            if (type == 'vip' && !this.$store.state.login.Token) {
                this.$confirm(this.$t('goToLoginTip'), {
                    confirmButtonText: this.$t('confirm'),
                    cancelButtonText: this.$t('cancel'),
                }).then(() => {
                    this.$router.push({ path: '/pc/login' })
                })
                return
            }
            if (type == 'vip' && this.level == 0) {
                Toast(this.$t('openVipTip'))
                return
            }
            this.$router.push({
                path: '/pc/detail',
                query: {
                    address,
                    view_count
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.new-currency-content-center {
    width: 408px;
    border-radius: 14px;
    background-color: #FFFFFF;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
    padding-bottom: 20px;
    cursor: pointer;

    .info-top {
        padding-bottom: 15px;
        background-color: #F7F7F7;
        border-radius: 14px 14px 0px 0px;
        padding: 22px 15px 30px;

        .hot-title {
            font-weight: bold;
            font-size: 20px;
            line-height: 1;
            margin-bottom: 12px;
        }

        .hot-chart {
            width: 74px;
            height: 40px;
        }

        .top-vip-icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;
        }

    }

    .vip-icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
    }
}
</style>